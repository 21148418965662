// React
import React from 'react';
import Helmet from 'react-helmet';

// Gatsby
import { Link, graphql } from 'gatsby';

// Third-party
import get from 'lodash/get';

// Local components
import Layout from '../components/layout';

class RunsIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title');
    const events = get(this, 'props.data.allContentfulEvent.edges');

    return (
      <Layout location={this.props.location}>
        <div style={{ background: '#fff', color: '#373f49' }}>
          <Helmet title={siteTitle} />
          <div className="wrapper">
            <h2 className="section-headline">Events/Outings</h2>
            <div>
              Click on a description to see the details for that event/outing. Click on a track name
              to see track details.
            </div>
            <table style={{ width: '100%', marginTop: '8px' }}>
              <thead>
                <tr>
                  <th style={{ textAlign: 'left' }}>Description</th>
                  <th style={{ textAlign: 'left', width: '200px' }}>Track</th>
                  <th style={{ textAlign: 'left', width: '150px' }}>Date</th>
                </tr>
              </thead>
              <tbody>
                {events.map(({ node }) => {
                  return (
                    <tr key={node.slug}>
                      <td>
                        <Link to={`/event/${node.slug}`}>{node.name}</Link>
                      </td>
                      <td>
                        <Link to={`/track/${node.track.slug}`}>{node.track.name}</Link>
                      </td>
                      <td>{node.eventDate}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </Layout>
    );
  }
}

export default RunsIndex;

export const pageQuery = graphql`
  query EventsIndexQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulEvent(sort: { fields: [eventDate], order: DESC }) {
      edges {
        node {
          slug
          eventDate(formatString: "MM/DD/YYYY")
          name
          track {
            slug
            name
          }
        }
      }
    }
  }
`;
